<mat-card *ngIf="isPdf === true">
  <mat-radio-group aria-label="Select an option" [(ngModel)]="canvas" (change)="onViewerChanged($event)">
    <mat-radio-button [value]="true">Canvas</mat-radio-button>
    <mat-radio-button [value]="false">Pdf Viewer</mat-radio-button>
  </mat-radio-group>
</mat-card>
<mat-card *ngIf="isPdf === true && canvas === true">
  <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="currentPage"
                           (change)="onPageChanged($event)">
    <mat-button-toggle value="first">First Page</mat-button-toggle>
    <mat-button-toggle value="last">Last Page</mat-button-toggle>
  </mat-button-toggle-group>
</mat-card>

<form #form (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">
  <div
    class="capture-form-canvas"
    fxLayout="column"
    fxLayoutGap="8px"
    fxLayoutWrap
    fxLayoutAlign="flex-start"
  >
    <mat-form-field fxFlex floatLabel="auto">
      <mat-label>Nom Destinataire</mat-label>
      <input
        matInput
        name="recipientPartyName"
        [(ngModel)]="invoice.recipient_party.company_name"
        #name
        (focus)="onFocus(0)"
        autocomplete="off"
      />
      <button
        mat-button
        matSuffix
        mat-icon-button
        *ngIf="invoice.recipient_party.company_name"
        aria-label="Clear"
        (click)="invoice.recipient_party.company_name = ''"
        tabindex="-1"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field fxFlex floatLabel="auto">
      <mat-label>ICE Destinataire</mat-label>
      <input
        matInput
        name="recipientPartyLegalId"
        [(ngModel)]="invoice.recipient_party.company_legal_id"
        (focus)="onFocus(1)"
        autocomplete="off"
        #iceRecipient="ngModel"
        #recipientPartyLegalId
      />
      <button
        mat-button
        *ngIf="invoice.recipient_party.company_legal_id"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="invoice.recipient_party.company_legal_id = ''"
        tabindex="-1"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-error>
        Identifiant différent de celui de l’entreprise ({{ legalId }})
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex floatLabel="auto">
      <mat-label>N° de Facture</mat-label>
      <input
        matInput
        name="invoiceNumber"
        [(ngModel)]="invoice.invoice_number"
        (focus)="onFocus(2)"
        autocomplete="off"
        #invoiceNumber
      />
      <button
        mat-button
        *ngIf="invoice.invoice_number"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="invoice.invoice_number = ''"
        tabindex="-1"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field fxFlex floatLabel="auto">
      <mat-label>Référence Commande</mat-label>
      <input
        matInput
        name="orderReference"
        [(ngModel)]="invoice.orderReference"
        (focus)="onFocus(3)"
        autocomplete="off"
        #orderReference
      />
      <button
        mat-button
        *ngIf="invoice.orderReference"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="invoice.orderReference = ''"
        tabindex="-1"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field fxFlex floatLabel="auto">
      <mat-label>Date Facture</mat-label>
      <input
        matInput
        name="issueDate"
        [matDatepicker]="issueDatePicker"
        [(ngModel)]="invoice.issue_date"
        (focus)="onFocus(4)"
        autocomplete="off"
        appDateInFuture
        #issueDate="ngModel"
        #issueDateRef
      />

      <mat-error *ngIf="issueDate.errors?.dateInFuture">
        Date supérieure à la date du jour ({{ today | date:'shortDate' }})
      </mat-error>
      <mat-error *ngIf="issueDate.errors?.fiveYearsBefore">
        La date ne doit pas être antérieure de 5 ans
      </mat-error>

      <mat-error *ngIf="issueDate.errors && !issueDate.errors?.dateInFuture && !issueDate.errors?.fiveYearsBefore">
        Date mal formatée
      </mat-error>
      <button
        mat-button
        *ngIf="invoice.issue_date"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="invoice.issue_date = ''"
        tabindex="-1"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-datepicker-toggle
        matSuffix
        [for]="issueDatePicker"
        tabindex="-1"
      ></mat-datepicker-toggle>
      <mat-datepicker #issueDatePicker tabindex="-1"></mat-datepicker>
    </mat-form-field>

    <mat-form-field fxFlex floatLabel="auto">
      <mat-label>Total HT</mat-label>
      <input
        matInput
        name="taxExclusiveAmount"
        [(ngModel)]="invoice.total_amount"
        (focus)="onFocus(5)"
        autocomplete="off"
        #taxExclusiveAmount
      />
      <button
        mat-button
        *ngIf="invoice.total_amount"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="invoice.total_amount = ''"
        tabindex="-1"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field fxFlex floatLabel="auto">
      <mat-label>Total Taxes</mat-label>
      <input
        matInput
        name="taxAmount"
        [(ngModel)]="invoice.tax_total"
        (focus)="onFocus(6)"
        autocomplete="off"
        #taxAmount
      />
      <button
        mat-button
        *ngIf="invoice.tax_total"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="invoice.tax_total = ''"
        tabindex="-1"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field fxFlex floatLabel="auto">
      <mat-label>Net à Payer</mat-label>
      <input
        matInput
        name="payableAmount"
        [(ngModel)]="invoice.legal_monetary_total"
        (focus)="onFocus(7)"
        autocomplete="off"
        #payableAmount
      />
      <button
        mat-button
        *ngIf="invoice.legal_monetary_total"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="invoice.legal_monetary_total = ''"
        tabindex="-1"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field fxFlex floatLabel="auto">
      <mat-label>Nom Emetteur</mat-label>
      <input
        matInput
        name="senderPartyName"
        [(ngModel)]="invoice.sender_party.company_name"
        (focus)="onFocus(8)"
        autocomplete="off"
        #senderPartyName
      />
      <button
        mat-button
        *ngIf="invoice.sender_party.company_name"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="invoice.sender_party.company_name = ''"
        tabindex="-1"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field fxFlex floatLabel="auto">
      <mat-label>ICE Emetteur</mat-label>
      <input
        matInput
        name="senderPartyLegalId"
        [(ngModel)]="invoice.sender_party.company_legal_id"
        (focus)="onFocus(9)"
        autocomplete="off"
        #iceSender="ngModel"
        #senderPartyLegalId
      />
      <button
        mat-button
        *ngIf="invoice.sender_party.company_legal_id"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="invoice.sender_party.company_legal_id = ''"
        tabindex="-1"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-error>
        Identifiant non déclaré
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex floatLabel="auto">
      <mat-label>RIB Emetteur</mat-label>
      <input
        matInput
        name="payeeFinancialAccount"
        [(ngModel)]="invoice.payeeFinancialAccount"
        (focus)="onFocus(10)"
        autocomplete="off"
        #payeeFinancialAccount
      />
      <button
        mat-button
        *ngIf="invoice.payeeFinancialAccount"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="invoice.payeeFinancialAccount = ''"
        tabindex="-1"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field fxFlex floatLabel="auto">
      <mat-label>Devise</mat-label>
      <mat-select [(ngModel)]="invoice.documentCurrencyCode" name="documentCurrencyCode" #currencySelect="matSelect">
        <mat-option>None</mat-option>
        <mat-option *ngFor="let currency of currencies" [value]="currency.id">
          {{ currency.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button fxFlex="nogrow" mat-flat-button color="primary">ENREGISTRER</button>
  </div>
</form>

<mat-card>
  <mat-card-content class="mat-card-content-gray">
    invoiceId: {{ invoiceId }}
  </mat-card-content>
</mat-card>
<app-spinner></app-spinner>
